/* ==========================================================================
   #CONTAINER
   ========================================================================== */

@mixin inner-container {
  box-sizing: content-box;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @include bp-small {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include bp-medium {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include bp-large {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.o-inner-container {
  @include inner-container;
}
