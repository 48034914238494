.c-site-nav {
  display: none;

  @include bp-medium {
    display: block;
  }
}

.c-site-nav__list {
  margin-bottom: 0;
}

.c-site-nav__list-item {
  display: inline-block;
  margin-bottom: 0;
}

.c-site-nav__link {
  color: $color-nav-text;
  font-size: 18px;
  font-weight: $font-weight-body;
  margin: 0 0 0 14px;
  padding: 4px 2px;
  text-decoration: none;
  @include text-underline;
  white-space: nowrap;

  &:before {
    background-color: transparent;
    transition: $global-transition-quick;
  }

  @include hover {
    color: $color-nav-text;

    &:before {
      background-color: $color-brand-grey-dark;
    }
  }
}

.c-site-nav__link--active {
  @include text-underline;
}

.c-site-nav__dropdown {
  @include bp-medium {
    display: inline-block;
    position: relative;
  }
}

.c-site-nav__children {
  background: #e7e7e7;
  border-radius: 5px;
  display: none;
  margin: 6px 0;
  padding: 6px 0 2px;
  position: relative;

  @include bp-medium {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 8px rgba(0,0,0, 0.175);
    left: 50%;
    margin: 0;
    max-width: 280px;
    min-width: 180px;
    padding: 0;
    position: absolute;
    top: calc(100% + 10px);
    transform: translateX(-50%);
  }

  &.is-visible {
    display: block;
  }

  > .c-site-nav__list-item {
    display: block;

    .c-site-nav__link {
      color: #444;
      display: block;
      font-size: 16px;
      margin: 0;
      padding: 4px 25px;

      &:before {
        content: none !important;
      }

      @include hover {
        color: #000;
      }
    }

    .c-site-nav__link--active {
      font-weight: bold;
    }
  }

  > .c-site-nav__list-item:first-child .c-site-nav__link {
    padding-top: 10px;
  }

  > .c-site-nav__list-item:last-child .c-site-nav__link {
    padding-bottom: 10px;
  }

  &:after {
    @include bp-medium {
      content: "";
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #fff;
      left: 50%;
      position: absolute;
      bottom: 100%;
      transform: translateX(-50%);
      width: 0;
    }
  }
}

button.c-site-nav__child-trigger {
  appearance: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 10px;

  position: absolute;
  top: 0;
  right: 0;

  @include bp-medium {
    color: $color-nav-text;
    padding: 6px 2px;
    position: static;
  }

  .icon-chevron-down {
    @include bp-medium {
      color: $color-nav-text;
    }
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
    cursor: pointer;
  }

  &:active {
    transform: scale(0.9);
  }

  &:hover {
    opacity: 0.8;
  }
}
