///* ========================================================================
//   #BREAKPOINTS
//   ======================================================================== */

@mixin bp-small {
  @media (min-width: $bp-small) {
    @content;
  }
}

@mixin bp-small-max {
  @media (max-width: $bp-small - 1) {
    @content;
  }
}

@mixin bp-smallish {
  @media (min-width: $bp-smallish) {
    @content;
  }
}

@mixin bp-smallish-max {
  @media (max-width: $bp-smallish - 1) {
    @content;
  }
}

@mixin bp-medium {
  @media (min-width: $bp-medium) {
    @content;
  }
}

@mixin bp-medium-max {
  @media (max-width: $bp-medium - 1) {
    @content;
  }
}

@mixin bp-large {
  @media (min-width: $bp-large) {
    @content;
  }
}

@mixin bp-large-max {
  @media (max-width: $bp-large - 1) {
    @content;
  }
}

@mixin bp-xlarge {
  @media (min-width: $bp-xlarge) {
    @content;
  }
}

@mixin bp-full {
  @media (min-width: $bp-full) {
    @content;
  }
}

@mixin bp-jumbo {
  @media (min-width: $bp-jumbo) {
    @content;
  }
}
