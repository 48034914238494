.o-row {
  @include container();

  @include bp-medium {
    display: flex;
  }

  &--middle {
    align-items: center;
  }
}
