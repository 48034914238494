.c-nav-toggle.c-nav-toggle {
  align-items: center;
  background-color: $color-nav-feature;
  border-radius: 15px;
  border: 0;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  line-height: 1;
  outline: 0;
  padding: 10px 20px;
  text-align: center;
  -webkit-appearance: none;
  z-index: 10;

  @include bp-medium {
    display: none;
  }
}

.c-nav-toggle__text {
  color: $color-brand-white;
  font-size: 12px;
  line-height: 1;
  margin-right: 10px;
  text-transform: uppercase;
}

$nav-toggle-height: 14px;
$nav-toggle-height-half: $nav-toggle-height / 2;

.c-nav-toggle__icon {
  height: $nav-toggle-height;
  border-right: 0;
  position: relative;
  width: 22px;
}

.c-nav-toggle__stroke {
  background: #fff;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  transition: height ease 0.2s, transform ease 0.2s;
  transform: translateZ(0) scale(1);

  &--1 {
    top: 0;

    .u-nav-open & {
      transform: translateY($nav-toggle-height-half) scale(0);
    }
  }

  &--2 {
    top: $nav-toggle-height-half;

    .u-nav-open & {
      transform: rotateZ(45deg);
    }
  }

  &--3 {
    top: $nav-toggle-height-half;

    .u-nav-open & {
      transform: rotateZ(-45deg);
    }
  }

  &--4 {
    top: $nav-toggle-height;

    .u-nav-open & {
      transform: translateY(-$nav-toggle-height-half) scale(0);
    }
  }
}
