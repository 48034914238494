/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-bg;
  background-color: $color-brand-black;
  border-top: 2px solid #f9f9f9;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;

  @include bp-medium {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include bp-large {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.c-footer__inner {
  @include inner-container;
  max-width: 1200px;
  position: relative;
  z-index: 1;

  @include bp-small {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.c-footer__content-wrapper {
  @include bp-medium {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

.c-footer__left {
  @include bp-medium {
    width: 50%;
  }
}

.c-footer__right {
  text-align: center;
  position: relative;
  
  @include bp-large {
    min-height: 204px;
    min-width: 550px;
    width: 50%;
  }
}
