.c-form-btn {
  font-family: $font-family-body;
  padding: 8px 26px;

  @include bp-small {
    padding: 10px 26px;
  }

  @include bp-medium {
    @include rem(font-size, 16px);
  }

  @include bp-xlarge {
    @include rem(font-size, 18px);
  }
}
