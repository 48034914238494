.o-col {
  @include span(12);

  &.o-col--25 {
    @include bp-medium {
      @include span(3);
    }
  }

  &.o-col--33 {
    @include bp-medium {
      @include span(4);
    }
  }

  &.o-col--50 {
    @include bp-medium {
      @include span(6);
    }
  }


  &.o-col--66 {
    @include bp-medium {
      @include span(8);
    }
  }

  &.o-col--75 {
    @include bp-medium {
      @include span(9);
    }
  }
}

[class*=o-col]:last-child {
  margin-right: 0;

  > *:last-child {
    margin-bottom: 0;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}

[class*=o-col] {
  @include bp-medium {
    > *:last-child {
      margin-bottom: 0;

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.o-col--center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.o-col--left {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.o-col--right {
  margin-left: auto !important;
  margin-right: 0 !important;
}
