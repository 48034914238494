.c-heading-xlarge {
  @include rem(font-size, 28px);

  @include bp-medium {
    @include rem(font-size, 32px);
  }

  @include bp-large {
    @include rem(font-size, 48px);
  }
}