/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background-color: $color-bg;
  background-color: $color-brand-black;
  box-shadow: 0 0 0 0 rgba(#000, 0);
  box-sizing: border-box;
  left: 0;
  margin: auto;
  max-height: $header-height-desktop;
  position: fixed;
  right: 0;
  top: 0;
  transition: $global-transition;
  z-index: 5;

  &.not-at-top {
    box-shadow: 0 0 28px 0 rgba(#000, 0.1);

    &.is-unpinned {
      box-shadow: 0 0 28px 0 rgba(#000, 0);
      transform: translateY(-100%);
    }

    &.is-pinned {
      transform: translateY(0%);
    }
  }

  .u-nav-open.u-nav-open & {
    @include bp-medium-max {
      // background-color: $color-brand-grey;
      box-shadow: 0 0 0 0 rgba(#000, 0);
      transform: translateY(0%);
    }
  }
}

.c-header__inner {
  @include inner-container;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  transition: $global-transition;

  @include bp-medium {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.c-header__left {
  @include bp-medium {
    padding-right: 20px;
  }
}

.c-header__right {
  @include bp-medium {
    flex-grow: 0;
    min-width: 160px;
    width: 20%;
  }
}

.c-header__right {
  @include bp-medium {
    text-align: right;
  }
}

.c-header__middle {
  display: none;
  flex-grow: 2;

  @include bp-medium {
    display: block;
  }
}
