@charset "UTF-8";
/*
  Project: LittleMan
  Author: James Barr
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.c-clients-filters__item a:before {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

[class^=icon-], [class*=icon-] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon';
  src: url("/assets/fonts/icomoon.eot?fcl1hj");
  src: url("/assets/fonts/icomoon.eot?fcl1hj#iefix") format("embedded-opentype"), url("/assets/fonts/icomoon.ttf?fcl1hj") format("truetype"), url("/assets/fonts/icomoon.woff?fcl1hj") format("woff"), url("/assets/fonts/icomoon.svg?fcl1hj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon-chevron-down:before {
  content: "\e911";
}

.icon-link:before {
  content: "\e910";
}

.icon-close:before, .c-clients-filters__item a:before {
  content: "\e90b";
}

.icon-facebook:before {
  content: "\e900";
}

.icon-google-plus:before {
  content: "\e901";
}

.icon-linked-in:before {
  content: "\e902";
}

.icon-locations:before {
  content: "\e903";
}

.icon-mail:before {
  content: "\e904";
}

.icon-map:before {
  content: "\e905";
}

.icon-phone:before {
  content: "\e906";
}

.icon-quotes:before {
  content: "\e907";
}

.icon-twitter:before {
  content: "\e908";
}

.icon-checkmark-circle:before {
  content: "\e909";
}

.icon-cross-circle:before {
  content: "\e90a";
}

.icon-envelope:before {
  content: "\e90d";
}

.icon-map-marker:before {
  content: "\e90e";
}

.icon-smartphone:before {
  content: "\e90f";
}

.icon-tick:before {
  content: "\e90c";
  color: #ffcb48;
}

/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.5rem;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: '“' '”' '‘' '’';
  font-style: italic;
}

blockquote p:first-child:before {
  content: open-quote;
}

blockquote p:last-child:after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  line-height: 1.28;
}

h1 {
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 768px) {
  h1 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 32px;
    font-size: 2rem;
  }
}

h2 {
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 768px) {
  h2 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media (min-width: 1024px) {
  h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

h3 {
  font-size: 20px;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  h3 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

@media (min-width: 1024px) {
  h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 768px) {
  h4 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

h5 {
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 768px) {
  h5 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

h6 {
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  h6 {
    font-size: 16px;
    font-size: 1rem;
  }
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #ffffff;
  color: #232526;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weighr: 400;
  line-height: 1.8;
  min-height: 100%;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  body {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 16px;
    font-size: 1rem;
  }
}

main {
  padding-top: 51px;
}

@media (min-width: 768px) {
  main {
    padding-top: 55px;
  }
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  /* [1] */
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #232526;
  font-weight: bold;
  text-decoration: underline;
}

a:hover {
  color: #232526;
  text-decoration: none;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

.o-col {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .o-col.o-col--25 {
    width: 22.18045%;
    float: left;
    margin-right: 3.7594%;
  }
}

@media (min-width: 768px) {
  .o-col.o-col--33 {
    width: 30.82707%;
    float: left;
    margin-right: 3.7594%;
  }
}

@media (min-width: 768px) {
  .o-col.o-col--50 {
    width: 48.1203%;
    float: left;
    margin-right: 3.7594%;
  }
}

@media (min-width: 768px) {
  .o-col.o-col--66 {
    width: 65.41353%;
    float: left;
    margin-right: 3.7594%;
  }
}

@media (min-width: 768px) {
  .o-col.o-col--75 {
    width: 74.06015%;
    float: left;
    margin-right: 3.7594%;
  }
}

[class*=o-col]:last-child {
  margin-right: 0;
}

[class*=o-col]:last-child > *:last-child {
  margin-bottom: 0;
}

[class*=o-col]:last-child > *:last-child > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  [class*=o-col] > *:last-child {
    margin-bottom: 0;
  }
  [class*=o-col] > *:last-child > *:last-child {
    margin-bottom: 0;
  }
}

.o-col--center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.o-col--left {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.o-col--right {
  margin-left: auto !important;
  margin-right: 0 !important;
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.o-inner-container {
  box-sizing: content-box;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 480px) {
  .o-inner-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .o-inner-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) {
  .o-inner-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}

@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.o-row:after {
  content: " ";
  display: block;
  clear: both;
}

@media (min-width: 768px) {
  .o-row {
    display: flex;
  }
}

.o-row--middle {
  align-items: center;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.o-wrapper:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.o-wrapper-triangle {
  bottom: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 1600px;
  z-index: -1;
}

.o-wrapper-triangle:before {
  background-color: #f9f9f9;
  content: '';
  left: 0;
  position: absolute;
  top: 0;
  transform: skew(-18deg);
  transform-origin: top;
  bottom: 0;
  width: 70%;
  z-index: -1;
}

.c-breadcrumb {
  text-align: center;
  margin-top: 32px;
}

@media (min-width: 768px) {
  .c-breadcrumb {
    margin-top: 40px;
  }
}

.c-breadcrumb__inner {
  background: #eeeeee;
  border-radius: 50px;
  display: inline-block;
  padding: 6px 16px 10px;
  line-height: 1;
}

.c-breadcrumb__link,
.c-breadcrumb__span {
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 4px;
}

.c-breadcrumb__link {
  text-decoration: none;
}

.c-breadcrumb__link:hover, .c-breadcrumb__link:focus {
  text-decoration: underline;
}

.c-btn-header-cta.c-btn-header-cta {
  display: none;
}

@media (min-width: 768px) {
  .c-btn-header-cta.c-btn-header-cta {
    display: inline-block;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.c-btn {
  background-color: #ff6f2b;
  box-shadow: 0 0 0 transparent;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 2px 26px 4px;
  transition: all ease-in-out 0.15s;
  border-radius: 5px;
  border-width: 0;
  text-decoration: none;
}

.c-btn:hover, .c-btn:focus {
  background-color: #f56927;
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.12);
}

@media (max-width: 479px) {
  .c-btn-wrapper {
    text-align: center;
  }
}

.c-checkbox__input {
  opacity: 0;
  position: absolute;
  left: -9999em;
}

.c-checkbox__stylised {
  background-color: transparent;
  box-sizing: border-box;
  border: 2px solid #ff6f2b;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  transition: background-color ease 0.15s;
  margin-right: 14px;
  width: 24px;
}

.c-checkbox__input:checked ~ .c-checkbox__label .c-checkbox__stylised {
  background-color: #ff6f2b;
}

.c-checkbox__label:active .c-checkbox__stylised {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03) inset;
}

.c-checkbox__icon {
  color: #fff;
  display: block;
  font-size: 12px;
  padding: 4px 3px;
  transform: scale(0);
  transition: transform ease 0.1s;
}

.c-checkbox__input:checked ~ .c-checkbox__label .c-checkbox__icon {
  transform: scale(1);
}

.c-clients-filters {
  margin-left: 0;
}

@media (min-width: 768px) {
  .c-clients-filters {
    margin-left: -15px;
    width: calc(100% + 15px);
  }
}

.c-clients-filters__item {
  display: inline-block;
  margin-bottom: 0;
}

.c-clients-filters__item:first-child a:before {
  content: none;
}

.c-clients-filters__item a {
  display: block;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 12px;
  padding: 0 15px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.c-clients-filters__item a:before {
  bottom: 0;
  font-size: 11px;
  font-weight: normal;
  left: 0;
  opacity: 0;
  transform: translatex(15px);
  transition: transform ease 0.15s, opacity ease 0.1s;
  position: absolute;
  font-size: 9px;
  top: 3px;
}

@media (min-width: 768px) {
  .c-clients-filters__item a:before {
    font-size: 11px;
    top: 3px;
  }
}

.c-clients-filters__item.is-selected a {
  color: #ff6f2b;
}

.c-clients-filters__item.is-selected a:before {
  opacity: 1;
  transform: translatex(0);
}

.c-confirmation {
  background-color: #c8f7b5;
  border: 1px solid #005200;
  border-radius: 5px;
  padding: 15px;
  margin-top: 1.3rem;
}

@media (min-width: 768px) {
  .c-confirmation {
    padding: 20px;
  }
}

.c-confirmation__heading {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 16px;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .c-confirmation__heading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 1920px) {
  .c-confirmation__heading {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.c-confirmation__message {
  margin-bottom: 8px;
}

.c-confirmation__message:last-child {
  margin-bottom: 0;
}

.c-confirmation__icon {
  color: #005200;
  display: inline-block;
  margin-right: 4px;
  font-size: 20px;
  transform: translateY(3px);
}

.c-copy-large {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7;
}

@media (min-width: 1024px) {
  .c-copy-large {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .c-copy-large {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 36px;
    margin-bottom: 2.25rem;
  }
}

@media (min-width: 1920px) {
  .c-copy-large {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.c-copy-lighten {
  opacity: 0.5;
}

.c-errors {
  background-color: #f6cece;
  border: 1px solid #be3333;
  border-radius: 5px;
  padding: 15px;
  margin-top: 1.3rem;
}

@media (min-width: 768px) {
  .c-errors {
    padding: 20px;
  }
}

.c-errors__heading {
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 16px;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .c-errors__heading {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 1920px) {
  .c-errors__heading {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.c-errors__list {
  margin-bottom: 0;
}

.c-errors__list li:last-child {
  margin-bottom: 0;
}

.c-errors__icon {
  color: #be3333;
  display: inline-block;
  margin-right: 4px;
  font-size: 20px;
  transform: translateY(3px);
}

.c-contact {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: left;
}

@media (min-width: 480px) {
  .c-contact {
    margin: 0 16px;
  }
}

@media (min-width: 768px) {
  .c-contact {
    display: inline-block;
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .c-contact {
    display: block;
    padding: 28px 24px;
  }
}

@media (min-width: 1280px) {
  .c-contact {
    bottom: 0;
    box-sizing: border-box;
    transition: all ease-out 0s, padding ease-out 0.15s opacity ease 0.15s;
    padding: 32px;
    position: absolute;
    right: 0;
    width: 600px;
  }
}

@media (min-width: 1280px) {
  .c-contact.is-floating {
    bottom: 20px;
    position: fixed;
    right: 20px;
  }
}

@media (min-width: 1280px) {
  .c-contact.is-collapsed {
    padding: 10px;
    width: 480px;
  }
}

.c-contact__top {
  background-color: #fff;
  padding-bottom: 24px;
  margin-bottom: 14px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 1280px) {
  .c-contact__top {
    padding-bottom: 24px;
    margin-bottom: 14px;
  }
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__top {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.c-contact__top:before {
  background-color: #ff6f2b;
  content: '';
  bottom: 0;
  height: 4px;
  left: 0;
  max-width: 81px;
  opacity: 1;
  position: absolute;
  transition: max-width ease-out 0.2s, opacity ease-out 0.15s;
  width: 33%;
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__top:before {
    max-width: 0;
    opacity: 0;
    transition: max-width ease-out 0.2s, opacity ease-out 0.15s;
  }
}

@media (min-width: 1280px) {
  .c-contact__bottom {
    height: 32px;
    opacity: 1;
    overflow: hidden;
    transition: max-width ease-out 0.2s, opacity ease-out 0.15s;
  }
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__bottom {
    height: 0;
    opacity: 0;
    transition: max-width ease-out 0.2s, opacity ease-out 0.15s;
  }
}

.c-contact__top,
.c-contact__bottom {
  transition: all ease-out 0.15s;
}

@media (min-width: 600px) {
  .c-contact__top,
  .c-contact__bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 768px) {
  .c-contact__top,
  .c-contact__bottom {
    display: block;
  }
}

@media (min-width: 1024px) {
  .c-contact__top,
  .c-contact__bottom {
    display: flex;
  }
}

.c-contact__image {
  border-radius: 50%;
  margin-right: 16px;
  transition: all ease-out 0.15s;
  width: 55px;
}

@media (min-width: 768px) {
  .c-contact__image {
    width: 65px;
  }
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__image {
    margin-right: 8px;
    width: 50px;
  }
}

.c-contact__profile {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-right: 14px;
}

@media (min-width: 1024px) {
  .c-contact__profile {
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__profile {
    margin-right: 10px;
  }
}

.c-contact__address {
  margin-bottom: 16px;
}

@media (min-width: 1024px) {
  .c-contact__address {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .c-contact__address {
    font-size: 16px;
    font-size: 1rem;
  }
}

.c-contact__contact {
  background-color: #ff6f2b;
  border-radius: 10px;
  color: #ffffff;
  padding: 6px 12px;
  transition: all ease-out 0.15s;
}

@media (min-width: 480px) {
  .c-contact__contact {
    padding: 8px 16px;
  }
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__contact {
    padding: 4px 8px;
    font-size: 14px;
  }
}

.c-contact__contact [class*=icon-] {
  margin-right: 12px;
}

.c-contact__contact .icon-phone {
  font-size: 18px;
}

@media (min-width: 768px) {
  .c-contact__contact .icon-phone {
    font-size: 20px;
  }
}

.c-contact__contact .icon-mail {
  font-size: 11px;
}

@media (min-width: 768px) {
  .c-contact__contact .icon-mail {
    font-size: 12px;
  }
}

.c-contact__phone,
.c-contact__email,
.c-contact__social-profiles {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.c-contact__link {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
  transition: all ease-out 0.15s;
}

@media (min-width: 768px) {
  .c-contact__link {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media (min-width: 1280px) {
  .c-contact__link {
    line-height: 1.8;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media (min-width: 1280px) {
  .is-collapsed .c-contact__link {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
  }
}

.c-contact__link:hover, .c-contact__link:focus {
  color: #ffffff;
  text-decoration: underline;
}

.c-contact__profile-link {
  align-items: center;
  background-color: #ff6f2b;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  margin-left: 8px;
  text-align: center;
  text-decoration: none;
  transition: all ease-out 0.15s;
  width: 32px;
}

.c-contact__profile-link:hover, .c-contact__profile-link:focus {
  background-color: #f56927;
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.12);
}

.c-contact__profile-link--facebook {
  font-size: 19px;
}

.c-contact__profile-link--linkedin {
  font-size: 16px;
}

.c-contact__name {
  font-weight: bold;
}

@media (min-width: 1280px) {
  .c-contact__name {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: normal;
    margin-bottom: 2px;
  }
}

.c-contact__role {
  font-size: 13px;
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.c-footer-legal {
  color: #ff6f2b;
  font-size: 13px;
  font-size: 0.8125rem;
  margin-bottom: 0;
  margin-top: 32px;
  text-align: center;
}

@media (min-width: 768px) {
  .c-footer-legal {
    font-size: 14px;
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 54px;
    text-align: left;
  }
}

.c-footer-nav {
  padding: 0 16px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .c-footer-nav {
    display: flex;
    padding: 0;
    margin-bottom: 0;
  }
}

.c-footer-nav__list {
  float: left;
  margin-bottom: 0;
  width: 50%;
}

@media (min-width: 1024px) {
  .c-footer-nav__list {
    width: 30.82707%;
    float: left;
    margin-right: 3.7594%;
  }
}

.c-footer-nav__list-item {
  display: block;
  margin-bottom: 10px;
}

.c-footer-nav__sublist .c-footer-nav__list-item {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0;
  margin-left: 16px;
}

@media (min-width: 1280px) {
  .c-footer-nav__sublist .c-footer-nav__list-item {
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 4px;
  }
}

.c-footer-nav__link {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7;
}

@media (min-width: 1024px) {
  .c-footer-nav__link {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .c-footer-nav__link {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 36px;
    margin-bottom: 2.25rem;
  }
}

@media (min-width: 1920px) {
  .c-footer-nav__link {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .c-footer-nav__link {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .c-footer-nav__link {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-footer-nav__link:hover, .c-footer-nav__link:focus {
  color: #eeeeee;
  text-decoration: underline;
}

.c-footer-nav__sublist .c-footer-nav__link {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
}

@media (min-width: 768px) {
  .c-footer-nav__sublist .c-footer-nav__link {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media (min-width: 1280px) {
  .c-footer-nav__sublist .c-footer-nav__link {
    font-size: 16px;
    font-size: 1rem;
  }
}

.c-footer-nav__sublist {
  border-left: 4px solid #ff6f2b;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background-color: #ffffff;
  background-color: #232526;
  border-top: 2px solid #f9f9f9;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
}

@media (min-width: 768px) {
  .c-footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (min-width: 1024px) {
  .c-footer {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.c-footer__inner {
  box-sizing: content-box;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px;
  position: relative;
  z-index: 1;
}

@media (min-width: 480px) {
  .c-footer__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .c-footer__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) {
  .c-footer__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 480px) {
  .c-footer__inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 768px) {
  .c-footer__content-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .c-footer__left {
    width: 50%;
  }
}

.c-footer__right {
  text-align: center;
  position: relative;
}

@media (min-width: 1024px) {
  .c-footer__right {
    min-height: 204px;
    min-width: 550px;
    width: 50%;
  }
}

.c-form-btn {
  font-family: "Open Sans", sans-serif;
  padding: 8px 26px;
}

@media (min-width: 480px) {
  .c-form-btn {
    padding: 10px 26px;
  }
}

@media (min-width: 768px) {
  .c-form-btn {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .c-form-btn {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.c-btn--loader.is-loading .c-btn-form-text {
  opacity: 0;
}

.c-btn--loader {
  position: relative;
}

.c-form-loader {
  height: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  top: 0;
  opacity: 0;
  position: absolute;
  vertical-align: middle;
  width: 48px;
}

.c-btn--loader.is-loading .c-form-loader {
  opacity: 1;
}

.c-form-loader__icon,
.c-form-loader__icon:before,
.c-form-loader__icon:after {
  background-color: #ff6f2b;
  content: '';
  position: absolute;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-clip: padding-box;
}

.c-form-loader__icon:before {
  animation: growDot 0.8s linear 0s infinite;
  left: -15px;
}

.c-form-loader__icon {
  animation: growDot 0.8s linear 0.15s infinite;
  content: none;
  left: 21px;
}

.c-form-loader__icon:after {
  animation: growDot 0.8s linear 0.3s infinite;
  left: 15px;
}

@keyframes growDot {
  0% {
    box-shadow: 0 0 0 0 #ff6f2b;
  }
  25% {
    box-shadow: 0 0 0 3px #ff6f2b;
  }
  50% {
    box-shadow: 0 0 0 0 #ff6f2b;
  }
  100% {
    box-shadow: 0 0 0 0 #ff6f2b;
  }
}

.c-form-text {
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 3px;
  box-sizing: border-box;
  color: #232526;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  outline: 0;
  padding: 12px 15px;
  vertical-align: top;
  width: 100%;
}

@media (min-width: 1280px) {
  .c-form-text {
    padding: 14px 20px;
  }
}

.c-form-text:active {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03) inset;
}

.c-form-text:focus {
  border: 1px solid #ff6f2b;
}

.c-form-text::-webkit-input-placeholder {
  color: #232526;
}

.c-form-text::-moz-placeholder {
  color: #232526;
}

.c-form-text:-moz-placeholder {
  color: #232526;
}

.c-form-text:-ms-input-placeholder {
  color: #232526;
}

.c-form__inner {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.c-form__inner:after {
  content: " ";
  display: block;
  clear: both;
}

.c-form__col {
  margin-bottom: 15px;
  margin-bottom: 0.9375rem;
  position: relative;
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 480px) {
  .c-form__col {
    width: 49.10714%;
    float: left;
    margin-right: 1.78571%;
  }
}

@media (min-width: 768px) {
  .c-form__col {
    margin-bottom: 1.78571%;
  }
}

.c-form__col.c-form__col--textarea {
  width: 100%;
  float: left;
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 480px) {
  .c-form__col:nth-child(2n) {
    margin-right: 0;
  }
}

.c-form__col fieldset {
  margin-bottom: 0;
}

.c-form__col .o-list-bare,
.c-form__col .o-list-bare li {
  margin-bottom: 0;
}

.c-form {
  padding: 15px 0;
  position: relative;
}

@media (min-width: 768px) {
  .c-form {
    width: 65.41353%;
    float: left;
    margin-right: 3.7594%;
    padding: 0;
    float: none;
    margin: auto;
  }
}

.c-form:before {
  background-color: #fff;
  content: "";
  bottom: 0;
  left: -15px;
  position: absolute;
  right: -15px;
  top: 0;
  z-index: -1;
}

@media (min-width: 480px) {
  .c-form:before {
    left: -20px;
    right: -20px;
  }
}

@media (min-width: 768px) {
  .c-form:before {
    border-radius: 8px;
    bottom: -30px;
    left: -30px;
    right: -30px;
    top: -30px;
  }
}

.c-form__col--subscribe {
  margin-bottom: 20px;
}

@media (min-width: 480px) {
  .c-form__col--subscribe {
    margin-right: 0;
    width: 60%;
  }
}

@media (min-width: 480px) {
  .c-form__col--submit {
    margin-right: 0;
    width: 100%;
  }
}

.c-form__col--submit .c-form-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 480px) {
  .c-form__col--submit .c-form-btn {
    float: right;
  }
}

.c-form__col__checkboxes {
  padding-top: 10px;
}

@media (min-width: 480px) {
  .c-form__col__checkboxes {
    padding-top: 6px;
  }
}

@media (min-width: 768px) {
  .c-form__col__checkboxes {
    padding-top: 10px;
  }
}

.parsley-errors-list {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  top: 6px;
}

.parsley-errors-list li {
  color: #be3333;
  font-size: 9px;
  font-weight: bold;
  -webkit-font-smoothing: auto;
  line-height: 1;
  margin-bottom: 0;
  padding: 4px 7px 3px;
  text-transform: uppercase;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background-color: #ffffff;
  background-color: #232526;
  box-shadow: 0 0 0 0 transparent;
  box-sizing: border-box;
  left: 0;
  margin: auto;
  max-height: 54px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 300ms ease-in-out;
  z-index: 5;
}

.c-header.not-at-top {
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.1);
}

.c-header.not-at-top.is-unpinned {
  box-shadow: 0 0 28px 0 transparent;
  transform: translateY(-100%);
}

.c-header.not-at-top.is-pinned {
  transform: translateY(0%);
}

@media (max-width: 767px) {
  .u-nav-open.u-nav-open .c-header {
    box-shadow: 0 0 0 0 transparent;
    transform: translateY(0%);
  }
}

.c-header__inner {
  box-sizing: content-box;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  transition: all 300ms ease-in-out;
}

@media (min-width: 480px) {
  .c-header__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .c-header__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) {
  .c-header__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 768px) {
  .c-header__inner {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .c-header__left {
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .c-header__right {
    flex-grow: 0;
    min-width: 160px;
    width: 20%;
  }
}

@media (min-width: 768px) {
  .c-header__right {
    text-align: right;
  }
}

.c-header__middle {
  display: none;
  flex-grow: 2;
}

@media (min-width: 768px) {
  .c-header__middle {
    display: block;
  }
}

.c-heading-xlarge {
  font-size: 28px;
  font-size: 1.75rem;
}

@media (min-width: 768px) {
  .c-heading-xlarge {
    font-size: 32px;
    font-size: 2rem;
  }
}

@media (min-width: 1024px) {
  .c-heading-xlarge {
    font-size: 48px;
    font-size: 3rem;
  }
}

.c-image--narrow {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-image--shadow {
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
}

.c-isotope-grid {
  margin-left: -14px;
  width: calc(100% + 28px);
}

.c-isotope-grid:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

.c-isotope-item {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  float: left;
  padding-left: 1.20773%;
  padding-right: 1.20773%;
  padding-left: 14px;
  padding-right: 14px;
  margin-bottom: 20px;
}

@media (min-width: 480px) {
  .c-isotope-item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33333%;
    float: left;
    padding-left: 1.20773%;
    padding-right: 1.20773%;
    padding-left: 14px;
    padding-right: 14px;
  }
}

@media (min-width: 768px) {
  .c-isotope-item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    float: left;
    padding-left: 1.20773%;
    padding-right: 1.20773%;
    padding-left: 14px;
    padding-right: 14px;
  }
}

@media (min-width: 1024px) {
  .c-isotope-item {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 16.66667%;
    float: left;
    padding-left: 1.20773%;
    padding-right: 1.20773%;
    padding-left: 14px;
    padding-right: 14px;
  }
}

.c-isotope-item__title {
  display: none;
}

.c-isotope-item__inner {
  overflow: hidden;
  position: relative;
}

.c-isotope-item__link {
  display: block;
  text-decoration: none;
}

.c-isotope-item__link:hover .icon-link, .c-isotope-item__link:focus .icon-link {
  color: #fff;
  opacity: 1;
}

.c-isotope-item__link:hover .c-isotope-item__link-icon:before, .c-isotope-item__link:focus .c-isotope-item__link-icon:before {
  transform: translate3d(0, 0, 0);
}

.c-isotope-item__link-icon {
  box-sizing: border-box;
  bottom: 0;
  height: 38px;
  right: 0;
  position: absolute;
  text-align: center;
  width: 38px;
}

.c-isotope-item__link-icon:before {
  border-style: solid;
  border-width: 0 0 60px 60px;
  border-color: transparent transparent #232526 transparent;
  bottom: 0;
  content: "";
  height: 0;
  right: 0;
  position: absolute;
  transform: translate3d(60px, 0, 0);
  transition: all ease-out 200ms;
  width: 0;
  z-index: 0;
}

.c-isotope-item__link-icon .icon-link {
  display: inline-block;
  font-size: 20px;
  opacity: 0.2;
  padding-top: 8px;
  position: relative;
  transition: all ease 200ms;
  z-index: 1;
}

@media (max-width: 767px) {
  .c-mobile-menu {
    background-color: transparent;
    display: block;
    position: fixed;
    left: 100%;
    right: 0;
    bottom: 0;
    top: 0;
    transition: background-color ease-in-out 300ms, left 0s 300ms;
    padding-top: 50px;
    z-index: 3;
  }
  .u-nav-open .c-mobile-menu {
    background-color: #eeeeee;
    left: 0;
    transition: background-color ease-in-out 300ms;
  }
}

@media (min-width: 768px) {
  .c-mobile-menu {
    display: none;
  }
}

@media (max-width: 767px) {
  .c-mobile-menu__inner {
    box-sizing: content-box;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media (max-width: 767px) and (min-width: 480px) {
  .c-mobile-menu__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .c-mobile-menu__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) and (min-width: 1024px) {
  .c-mobile-menu__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) and (min-width: 480px) {
  .c-mobile-menu__inner {
    padding-bottom: 48px;
    padding-top: 48px;
  }
}

@media (max-width: 767px) and (min-width: 768px) {
  .c-mobile-menu__inner {
    padding-bottom: 56px;
    padding-top: 56px;
  }
}

@media (max-width: 767px) and (min-width: 1024px) {
  .c-mobile-menu__inner {
    padding-bottom: 72px;
    padding-top: 72px;
  }
}

@media (max-width: 767px) and (min-width: 1280px) {
  .c-mobile-menu__inner {
    padding-bottom: 96px;
    padding-top: 96px;
  }
}

.c-mobile-nav {
  text-align: center;
}

.c-mobile-nav__list-item {
  margin-bottom: 12px;
  opacity: 0;
  position: relative;
  transition: opacity 150ms ease-in-out;
}

.u-nav-open .c-mobile-nav__list-item {
  opacity: 1;
  transition: opacity 300ms 100ms ease-in-out;
}

.c-mobile-nav__link {
  font-size: 18px;
  padding: 4px 2px;
  text-decoration: none;
}

.c-mobile-nav__link--active {
  position: relative;
}

.c-mobile-nav__link--active:before {
  background-color: #ff6f2b;
  content: '';
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

.c-site-nav__children .c-mobile-nav__list-item {
  margin-bottom: 6px;
}

.c-site-nav__children .c-mobile-nav__link {
  font-size: 16px;
}

.c-nav-toggle.c-nav-toggle {
  align-items: center;
  background-color: #ff6f2b;
  border-radius: 15px;
  border: 0;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  line-height: 1;
  outline: 0;
  padding: 10px 20px;
  text-align: center;
  -webkit-appearance: none;
  z-index: 10;
}

@media (min-width: 768px) {
  .c-nav-toggle.c-nav-toggle {
    display: none;
  }
}

.c-nav-toggle__text {
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  margin-right: 10px;
  text-transform: uppercase;
}

.c-nav-toggle__icon {
  height: 14px;
  border-right: 0;
  position: relative;
  width: 22px;
}

.c-nav-toggle__stroke {
  background: #fff;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
  transition: height ease 0.2s, transform ease 0.2s;
  transform: translateZ(0) scale(1);
}

.c-nav-toggle__stroke--1 {
  top: 0;
}

.u-nav-open .c-nav-toggle__stroke--1 {
  transform: translateY(7px) scale(0);
}

.c-nav-toggle__stroke--2 {
  top: 7px;
}

.u-nav-open .c-nav-toggle__stroke--2 {
  transform: rotateZ(45deg);
}

.c-nav-toggle__stroke--3 {
  top: 7px;
}

.u-nav-open .c-nav-toggle__stroke--3 {
  transform: rotateZ(-45deg);
}

.c-nav-toggle__stroke--4 {
  top: 14px;
}

.u-nav-open .c-nav-toggle__stroke--4 {
  transform: translateY(-7px) scale(0);
}

.c-panel {
  background-position: center;
  background-size: cover;
}

.c-panel--grey {
  background-color: #f9f8f8;
}

.c-panel__inner {
  box-sizing: content-box;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
  padding-top: 40px;
  box-sizing: content-box;
}

@media (min-width: 480px) {
  .c-panel__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .c-panel__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1024px) {
  .c-panel__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 480px) {
  .c-panel__inner {
    padding-bottom: 48px;
    padding-top: 48px;
  }
}

@media (min-width: 768px) {
  .c-panel__inner {
    padding-bottom: 56px;
    padding-top: 56px;
  }
}

@media (min-width: 1024px) {
  .c-panel__inner {
    padding-bottom: 72px;
    padding-top: 72px;
  }
}

@media (min-width: 1280px) {
  .c-panel__inner {
    padding-bottom: 96px;
    padding-top: 96px;
  }
}

@media (min-width: 768px) {
  .c-panel--quote .c-panel__inner {
    padding-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .c-panel--quote .c-panel__inner {
    padding-top: 72px;
  }
}

@media (min-width: 1280px) {
  .c-panel--quote .c-panel__inner {
    padding-top: 72px;
  }
}

.c-panel--no-pt .c-panel__inner {
  padding-top: 0;
}

.c-panel--less-pb .c-panel__inner {
  padding-bottom: 20px;
}

@media (min-width: 480px) {
  .c-panel--less-pb .c-panel__inner {
    padding-bottom: 24px;
  }
}

@media (min-width: 768px) {
  .c-panel--less-pb .c-panel__inner {
    padding-bottom: 32px;
    padding-bottom: 40px;
  }
}

@media (min-width: 1024px) {
  .c-panel--less-pb .c-panel__inner {
    padding-bottom: 40px;
  }
}

.c-panel--light-text * {
  color: #ffffff;
}

.c-quote-panel-heading {
  text-align: center;
  margin-bottom: 24px;
}

.c-quote {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  position: relative;
}

@media (min-width: 768px) {
  .c-quote {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

.c-quote__copy {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7;
  margin-bottom: 24px;
}

@media (min-width: 1024px) {
  .c-quote__copy {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .c-quote__copy {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 36px;
    margin-bottom: 2.25rem;
  }
}

@media (min-width: 1920px) {
  .c-quote__copy {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .c-quote__copy {
    margin-bottom: 32px;
  }
}

@media (min-width: 1920px) {
  .c-quote__copy {
    margin-bottom: 56px;
  }
}

.c-quote__attribution {
  background-color: #eeeeee;
  border-radius: 8px;
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.7;
  margin-bottom: 32px;
}

@media (min-width: 1024px) {
  .c-quote__attribution {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 1280px) {
  .c-quote__attribution {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 36px;
    margin-bottom: 2.25rem;
  }
}

@media (min-width: 1920px) {
  .c-quote__attribution {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .c-quote__attribution {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}

.c-quote-wrapper:first-child:last-child .c-quote__attribution {
  margin-bottom: 0;
}

.c-quote__author-company {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

.slick-track {
  display: flex;
}

.slick-slide,
.slick-initialized .slick-slide {
  height: auto;
}

.slick-dots {
  line-height: 1;
  list-style: none;
  margin: 0;
  text-align: center;
}

@media (min-width: 768px) {
  .slick-dots {
    position: absolute;
    bottom: 8px;
    right: 0;
  }
}

@media (min-width: 1280px) {
  .slick-dots {
    transform: translateX(50%);
  }
}

.slick-dots li {
  display: inline-block;
  line-height: 1;
  margin: 0 5px;
}

.slick-dots li button {
  background-color: #ffffff;
  border-radius: 50%;
  border: 0;
  color: transparent;
  height: 14px;
  outline: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 14px;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  background-color: #eeeeee;
}

.slick-dots li.slick-active button {
  background-color: #ccc;
}

.c-select {
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: none !important;
  overflow: hidden;
  position: relative;
}

.c-select:before {
  border-left: 1px solid #eee;
  bottom: 0;
  content: '';
  position: absolute;
  right: 48px;
  top: 0;
  width: 1px;
}

.c-select select {
  color: #232526;
  outline: 0;
}

.c-select select:active {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03) inset;
}

.c-select select:focus {
  border: 1px solid #ff6f2b;
}

.c-select.option-selected select {
  color: #232526;
}

.c-select .c-select__icon {
  box-sizing: border-box;
  color: #ff6f2b;
  font-size: 13px;
  padding: 0 18px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 0;
}

.c-select.is-focussed {
  border: 1px solid #ff6f2b;
}

.c-select:active {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.03) inset;
}

.c-select__input {
  background: transparent !important;
  border-radius: 5px;
  border: 0 !important;
  display: block;
  font-weight: normal;
  padding: 15px 68px 16px 15px;
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
  text-indent: 0.01px;
  width: 100%;
  z-index: 2;
}

@media (min-width: 1280px) {
  .c-select__input {
    padding: 16px 68px 17px 20px;
  }
}

@media (min-width: 1920px) {
  .c-select__input {
    padding: 16px 68px 15px 15px;
  }
}

.c-select__input::-ms-expand {
  display: none;
}

.c-select__input option {
  color: #000;
}

.c-select__input option[data-label] {
  color: #232526;
}

.c-site-logo {
  line-height: 1;
}

.c-site-logo__image {
  height: 32px;
}

.c-site-nav {
  display: none;
}

@media (min-width: 768px) {
  .c-site-nav {
    display: block;
  }
}

.c-site-nav__list {
  margin-bottom: 0;
}

.c-site-nav__list-item {
  display: inline-block;
  margin-bottom: 0;
}

.c-site-nav__link {
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 0 14px;
  padding: 4px 2px;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
}

.c-site-nav__link:before {
  background-color: #ff6f2b;
  content: '';
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

.c-site-nav__link:before {
  background-color: transparent;
  transition: all 150ms ease-in-out;
}

.c-site-nav__link:hover, .c-site-nav__link:focus {
  color: #ffffff;
}

.c-site-nav__link:hover:before, .c-site-nav__link:focus:before {
  background-color: #cccccc;
}

.c-site-nav__link--active {
  position: relative;
}

.c-site-nav__link--active:before {
  background-color: #ff6f2b;
  content: '';
  bottom: 0;
  height: 1px;
  left: 0;
  position: absolute;
  right: 0;
}

@media (min-width: 768px) {
  .c-site-nav__dropdown {
    display: inline-block;
    position: relative;
  }
}

.c-site-nav__children {
  background: #e7e7e7;
  border-radius: 5px;
  display: none;
  margin: 6px 0;
  padding: 6px 0 2px;
  position: relative;
}

@media (min-width: 768px) {
  .c-site-nav__children {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.175);
    left: 50%;
    margin: 0;
    max-width: 280px;
    min-width: 180px;
    padding: 0;
    position: absolute;
    top: calc(100% + 10px);
    transform: translateX(-50%);
  }
}

.c-site-nav__children.is-visible {
  display: block;
}

.c-site-nav__children > .c-site-nav__list-item {
  display: block;
}

.c-site-nav__children > .c-site-nav__list-item .c-site-nav__link {
  color: #444;
  display: block;
  font-size: 16px;
  margin: 0;
  padding: 4px 25px;
}

.c-site-nav__children > .c-site-nav__list-item .c-site-nav__link:before {
  content: none !important;
}

.c-site-nav__children > .c-site-nav__list-item .c-site-nav__link:hover, .c-site-nav__children > .c-site-nav__list-item .c-site-nav__link:focus {
  color: #000;
}

.c-site-nav__children > .c-site-nav__list-item .c-site-nav__link--active {
  font-weight: bold;
}

.c-site-nav__children > .c-site-nav__list-item:first-child .c-site-nav__link {
  padding-top: 10px;
}

.c-site-nav__children > .c-site-nav__list-item:last-child .c-site-nav__link {
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .c-site-nav__children:after {
    content: "";
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    left: 50%;
    position: absolute;
    bottom: 100%;
    transform: translateX(-50%);
    width: 0;
  }
}

button.c-site-nav__child-trigger {
  appearance: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 768px) {
  button.c-site-nav__child-trigger {
    color: #ffffff;
    padding: 6px 2px;
    position: static;
  }
}

@media (min-width: 768px) {
  button.c-site-nav__child-trigger .icon-chevron-down {
    color: #ffffff;
  }
}

button.c-site-nav__child-trigger:hover, button.c-site-nav__child-trigger:focus, button.c-site-nav__child-trigger:active {
  outline: 0;
  cursor: pointer;
}

button.c-site-nav__child-trigger:active {
  transform: scale(0.9);
}

button.c-site-nav__child-trigger:hover {
  opacity: 0.8;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: '' !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #COLOR
   ========================================================================== */
.u-fill {
  background: #eeeeee;
}

.u-color-light {
  color: #ffffff;
}

.u-color-feature {
  color: #ff6f2b;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}

@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-shown\@small {
  display: block;
}

@media (min-width: 768px) {
  .u-shown\@small {
    display: none;
  }
}

@media (max-width: 767px) {
  .u-nav-open {
    overflow-y: hidden;
  }
}

/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */
.u-margin {
  margin: 1em;
}

.u-margin-top {
  margin-top: 1em;
}

.u-margin-right {
  margin-right: 1em;
}

.u-margin-bottom {
  margin-bottom: 1em;
}

.u-margin-left {
  margin-left: 1em;
}

.u-padding {
  padding: 1em;
}

.u-padding-top {
  padding-top: 1em;
}

.u-padding-right {
  padding-right: 1em;
}

.u-padding-bottom {
  padding-bottom: 1em;
}

.u-padding-left {
  padding-left: 1em;
}
