.c-mobile-menu {
  @include bp-medium-max {
    background-color: transparent;
    display: block;
    position: fixed;
    left: 100%;
    right: 0;
    bottom: 0;
    top: 0;
    transition: background-color ease-in-out 300ms, left 0s 300ms;
    padding-top: $header-height-mobile;
    z-index: 3;

    .u-nav-open & {
      background-color: $color-brand-grey;
      left: 0;
      transition: background-color ease-in-out 300ms;
    }
  }

  @include bp-medium {
    display: none;
  }
}

.c-mobile-menu__inner {
  @include bp-medium-max {
    @include inner-container;
    @include panel-padding;
  }
}
