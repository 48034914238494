@mixin large-copy {
  @include rem(font-size, 14px);
  line-height: 1.7;

  @include bp-large {
    @include rem(font-size, 16px);
  }

  @include bp-xlarge {
    @include rem(font-size, 18px);
    @include rem(margin-bottom, 36px);
  }

  @include bp-jumbo {
    @include rem(font-size, 20px);
  }
}
