$contact-transition: all ease-out 0.15s;
$height-opacity-transition-first: max-width ease-out 0.2s, opacity ease-out 0.15s;
$height-opacity-transition-second: max-width ease-out 0.2s, opacity ease-out 0.15s;

.c-contact {
  background: $color-bg;
  border-radius: 8px;
  box-shadow: 0 0 50px 0 rgba(#000, 0.2);
  padding: 16px;
  text-align: left;

  @include bp-small {
    margin: 0 16px;
  }

  @include bp-medium {
    display: inline-block;
    margin: 0;
  }

  @include bp-large {
    display: block;
    padding: 28px 24px;
  }

  @include bp-xlarge {
    bottom: 0;
    box-sizing: border-box;
    transition: all ease-out 0s, padding ease-out 0.15s opacity ease 0.15s;
    padding: 32px;
    position: absolute;
    right: 0;
    width: 600px;
  }

  &.is-floating {
    @include bp-xlarge {
      bottom: 20px;
      position: fixed;
      right: 20px;
    }
  }

  &.is-collapsed {
    @include bp-xlarge {
      padding: 10px;
      width: 480px;
    }
  }
}

.c-contact__top {
  background-color: #fff;
  padding-bottom: 24px;
  margin-bottom: 14px;
  overflow: hidden;
  position: relative;

  @include bp-xlarge {
    padding-bottom: 24px;
    margin-bottom: 14px;
  }

  .is-collapsed & {
    @include bp-xlarge {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &:before {
    background-color: $color-brand-orange;
    content: '';
    bottom: 0;
    height: 4px;
    left: 0;
    max-width: 81px;
    opacity: 1;
    position: absolute;
    transition: $height-opacity-transition-second;
    width: 33%;

    .is-collapsed & {
      @include bp-xlarge {
        max-width: 0;
        opacity: 0;
        transition: $height-opacity-transition-first;
      }
    }
  }
}

.c-contact__bottom {
  @include bp-xlarge {
    height: 32px;
    opacity: 1;
    overflow: hidden;
    transition: $height-opacity-transition-second;
  }

  .is-collapsed & {
    @include bp-xlarge {
      height: 0;
      opacity: 0;
      transition: $height-opacity-transition-first;
    }
  }
}

.c-contact__top,
.c-contact__bottom {
  transition: $contact-transition;

  @include bp-smallish {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @include bp-medium {
    display: block;
  }

  @include bp-large {
    display: flex;
  }
}

.c-contact__image {
  border-radius: 50%;
  margin-right: 16px;
  transition: $contact-transition;
  width: 55px;

  @include bp-medium {
    width: 65px;
  }

  .is-collapsed & {
    @include bp-xlarge {
      margin-right: 8px;
      width: 50px;
    }
  }
}

.c-contact__profile {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-right: 14px;

  @include bp-large {
    margin-bottom: 0;
  }

  .is-collapsed & {
    @include bp-xlarge {
      margin-right: 10px;
    }
  }
}

.c-contact__address {
  margin-bottom: 16px;

  @include bp-large {
    @include rem(font-size, 15px);
    margin-bottom: 0;
  }
  
  @include bp-xlarge {
    @include rem(font-size, 16px);
  }
}

.c-contact__contact {
  background-color: $color-feature-primary;
  border-radius: 10px;
  color: $color-text-light;
  padding: 6px 12px;
  transition: $contact-transition;

  @include bp-small {
    padding: 8px 16px;
  }

  .is-collapsed & {
    @include bp-xlarge {
      padding: 4px 8px;
      font-size: 14px;
    }
  }

  [class*=icon-] {
    margin-right: 12px;
  }

  .icon-phone {
    font-size: 18px;

    @include bp-medium {
      font-size: 20px;
    }
  }

  .icon-mail {
    font-size: 11px;

    @include bp-medium {
      font-size: 12px;
    }
  }
}

.c-contact__phone,
.c-contact__email,
.c-contact__social-profiles {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.c-contact__link {
  color: $color-text-light;
  font-weight: 400;
  text-decoration: none;
  transition: $contact-transition;

  @include bp-medium {
    @include rem(font-size, 15px);
  }

  @include bp-xlarge {
    line-height: 1.8;
    @include rem(font-size, 15px);
  }

  .is-collapsed & {
    @include bp-xlarge {
      @include rem(font-size, 15px);
      line-height: 1.6;
    }
  }

  @include hover {
    color: $color-text-light;
    text-decoration: underline;
  }
}

.c-contact__profile-link {
  align-items: center;
  background-color: $color-feature-primary;
  border-radius: 50%;
  color: $color-text-light;
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: center;
  margin-left: 8px;
  text-align: center;
  text-decoration: none;
  transition: $contact-transition;
  width: 32px;

  @include hover {
    background-color: $color-feature-primary-alt;
    color: $color-text-light;
    text-decoration: none;
    box-shadow: 0 1px 16px rgba(#000, 0.12);
  }

  &--facebook {
    font-size: 19px;
  }

  &--linkedin {
    font-size: 16px;
  }
}

.c-contact__name {
  font-weight: bold;
  
  @include bp-xlarge {
    @include rem(font-size, 15px);
    line-height: normal;
    margin-bottom: 2px;
  }
}

.c-contact__role {
  @include rem(font-size, 13px);
  text-transform: uppercase;
}
