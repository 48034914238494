/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.o-wrapper-triangle {
  bottom: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
  max-width: #{$maxSiteWidth};
  z-index: -1;

  &:before {
    background-color: #f9f9f9;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transform: skew(-18deg);
    transform-origin: top;
    bottom: 0;
    width: 70%;
    z-index: -1;
  }
}