.c-clients-filters {
  margin-left: 0;

  @include bp-medium {
    margin-left: -15px;
    width: calc(100% + 15px);
  }
}

.c-clients-filters__item {
  display: inline-block;
  margin-bottom: 0;

  &:first-child a:before {
    content: none;
  }

  a {
    display: block;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 12px;
    padding: 0 15px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &:before {
      @extend %icomoon;
      @extend .icon-close;
      bottom: 0;
      font-size: 11px;
      font-weight: normal;
      left: 0;
      opacity: 0;
      transform: translatex(15px);
      transition: transform ease 0.15s, opacity ease 0.1s;
      position: absolute;
      font-size: 9px;
      top: 3px;

      @include bp-medium {
        font-size: 11px;
        top: 3px;
      }
    }
  }

  &.is-selected a {
    color: $color-brand-orange;

    &:before {
      opacity: 1;
      transform: translatex(0);
    }
  }
}
