.c-panel {
  background-position: center;
  background-size: cover;
}

.c-panel--grey {
  background-color: #f9f8f8;
}

.c-panel__inner {
  @include inner-container;
  @include panel-padding;
  box-sizing: content-box;
}

.c-panel--quote .c-panel__inner {
  @include bp-medium {
    padding-bottom: 0;
  }

  @include bp-large {
    padding-top: 72px;
  }

  @include bp-xlarge {
    padding-top: 72px;
  }
}

.c-panel--no-pt .c-panel__inner {
  padding-top: 0;
}

.c-panel--less-pb .c-panel__inner {
  padding-bottom: 20px;

  @include bp-small {
    padding-bottom: 24px;
  }

  @include bp-medium {
    padding-bottom: 32px;
    padding-bottom: 40px;
  }

  @include bp-large {
    padding-bottom: 40px;
  }
}

.c-panel--light-text * {
  color: $color-text-light;
}
