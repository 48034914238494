/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
  line-height: 1.28;
}

h1 {
  @include rem(font-size, 24px);

  @include bp-medium {
    @include rem(font-size, 28px);
  }

  @include bp-large {
    @include rem(font-size, 32px);
  }
}

h2 {
  @include rem(font-size, 22px);

  @include bp-medium {
    @include rem(font-size, 25px);
  }

  @include bp-large {
    @include rem(font-size, 28px);
  }
}

h3 {
  @include rem(font-size, 20px);

  @include bp-medium {
    @include rem(font-size, 22px);
  }

  @include bp-large {
    @include rem(font-size, 24px);
  }
}

h4 {
  @include rem(font-size, 18px);

  @include bp-medium {
    @include rem(font-size, 20px);
  }
}

h5 {
  @include rem(font-size, 16px);

  @include bp-medium {
    @include rem(font-size, 18px);
  }
}

h6 {
  @include rem(font-size, 14px);

  @include bp-medium {
    @include rem(font-size, 16px);
  }
}
