.c-confirmation {
  background-color: #c8f7b5;
  border: 1px solid #005200;
  border-radius: $global-radius;
  padding: 15px;
  margin-top: 1.3rem;

  @include bp-medium {
    padding: 20px;
  }
}

.c-confirmation__heading {
  display: inline-block;
  @include rem(font-size, 16px);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 16px;
  text-transform: uppercase;

  @include bp-large {
    @include rem(font-size, 18px);
  }

  @include bp-jumbo {
    @include rem(font-size, 20px);
  }
}

.c-confirmation__message {
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-confirmation__icon {
  color: #005200;
  display: inline-block;
  margin-right: 4px;
  font-size: 20px;
  transform: translateY(3px);
}
