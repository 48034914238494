.c-form-text {
  background: $form-input-bg;
  border: $form-input-border;
  border-radius: 3px;
  box-sizing: border-box;
  color: $form-input-focussed-text-color;
  font-family: $font-family-body;
  font-weight: $font-weight-body;
  outline: 0;
  padding: 12px 15px;
  vertical-align: top;
  width: 100%;

  @include bp-xlarge {
    padding: 14px 20px;
  }

  &:active {
    box-shadow: $form-el-active-shadow;
  }

  &:focus {
    border: $form-input-border-focussed;
  }

  &.parsley-error {
    // border-color: #be3333;
  }

  @include placeholder {
    color: $form-input-placeholder-text-color;
  }
}
