.c-select {
  background: $form-input-bg;
  border: $form-input-border;
  border-radius: $global-radius;
  box-sizing: border-box;
  box-shadow: none !important;
  overflow: hidden;
  position: relative;

  &:before {
    border-left: $form-input-border;
    bottom: 0;
    content: '';
    position: absolute;
    right: 48px;
    top: 0;
    width: 1px;
  }

  select {
    color: $form-input-placeholder-text-color;
    outline: 0;

    &:active {
      box-shadow: $form-el-active-shadow;
    }

    &:focus {
      border: $form-input-border-focussed;
    }
  }

  &.option-selected select {
    color: $form-input-focussed-text-color;
  }

  .c-select__icon {
    box-sizing: border-box;
    color: $color-feature-primary;
    font-size: 13px;
    padding: 0 18px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 0;
  }

  &.is-focussed {
    border: $form-input-border-focussed;
  }

  &:active {
    box-shadow: $form-el-active-shadow;
  }
}

.c-select__input {
  background: transparent !important;
  border-radius: $global-radius;
  border: 0 !important;
  display: block;
  font-weight: normal;
  padding: 15px 68px 16px 15px;
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
  text-indent: 0.01px;
  width: 100%;
  z-index: 2;

  @include bp-xlarge {
    padding: 16px 68px 17px 20px;
  }

  @include bp-jumbo {
    padding: 16px 68px 15px 15px;
  }

  &::-ms-expand {
    display: none;
  }

  option {
    color: #000;

    &[data-label] {
      color: $color-text;
    }
  }
}
