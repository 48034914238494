@mixin text-underline {
  position: relative;

  &:before {
    background-color: $color-feature-primary;
    content: '';
    bottom: 0;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
  }
}
