$leftPushedGrid: (
  columns: 18,
  gutter-position: inside,
);

@include with-layout($leftPushedGrid) {
  .c-isotope-grid {
    @include clearfix()
    margin-left: -14px;
    width: calc(100% + 28px)
  }

  .c-isotope-item {
    @include span(9);
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 20px;
    
    @include bp-small {
      @include span(6);
      padding-left: 14px;
      padding-right: 14px;
    }
    
    @include bp-medium {
      @include span(4.5);
      padding-left: 14px;
      padding-right: 14px;
    }

    @include bp-large {
      @include span(3);
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}

.c-isotope-item__title {
  display: none;
}

.c-isotope-item__inner {
  overflow: hidden;
  position: relative;
}

.c-isotope-item__link {
  display: block;
  text-decoration: none;

  @include hover {
    .icon-link {
      color: #fff;
      opacity: 1;
    }

    .c-isotope-item__link-icon:before {
      transform: translate3d(0, 0, 0);
    }
  }
}

.c-isotope-item__link-icon {
  box-sizing: border-box;
  bottom: 0;
  height: 38px;
  right: 0;
  position: absolute;
  text-align: center;
  width: 38px;

  &:before {
    border-style: solid;
    border-width: 0 0 60px 60px;
    border-color: transparent transparent $color-brand-black transparent;
    bottom: 0;
    content: "";
    height: 0;
    right: 0;
    position: absolute;
    transform: translate3d(60px, 0, 0);
    transition: all ease-out 200ms;
    width: 0;
    z-index: 0;
  }

  .icon-link {
    display: inline-block;
    font-size: 20px;
    opacity: 0.2;
    padding-top: 8px;
    position: relative;
    transition: all ease 200ms;
    z-index: 1;
  }
}
