/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  background-color: $color-feature-primary;
  box-shadow: 0 0 0 rgba(#000, 0);
  color: $color-text-light;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 2px 26px 4px;
  transition: all ease-in-out 0.15s;
  border-radius: $global-radius;
  border-width: 0;
  text-decoration: none;

  @include hover {
    background-color: $color-feature-primary-alt;
    color: $color-text-light;
    text-decoration: none;
    box-shadow: 0 1px 16px rgba(#000, 0.12);
  }
}

.c-btn-wrapper {
  @include bp-small-max {
    text-align: center;
  }
}
