.c-errors {
  background-color: #f6cece;
  border: 1px solid #be3333;
  border-radius: $global-radius;
  padding: 15px;
  margin-top: 1.3rem;

  @include bp-medium {
    padding: 20px;
  }
}

.c-errors__heading {
  display: inline-block;
  @include rem(font-size, 16px);
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 16px;
  text-transform: uppercase;

  @include bp-large {
    @include rem(font-size, 18px);
  }
  
  @include bp-jumbo {
    @include rem(font-size, 20px);
  }
}

.c-errors__list {
  margin-bottom: 0;

  li:last-child {
    margin-bottom: 0;
  }
}

.c-errors__icon {
  color: #be3333;
  display: inline-block;
  margin-right: 4px;
  font-size: 20px;
  transform: translateY(3px);
}
