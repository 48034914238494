.c-btn-form-text {
  .c-btn--loader.is-loading & {
    opacity: 0;
  }
}

.c-btn--loader {
  position: relative;
}

.c-form-loader {
  height: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  top: 0;
  opacity: 0;
  position: absolute;
  vertical-align: middle;
  width: 48px;

  .c-btn--loader.is-loading & {
    opacity: 1;
  }
}

.c-form-loader__icon,
.c-form-loader__icon:before,
.c-form-loader__icon:after {
  background-color: $color-feature-primary;
  content: '';
  position: absolute;
  top: 0;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-clip: padding-box;
}

.c-form-loader__icon:before {
  animation: growDot 0.8s linear 0s infinite;
  left: -15px;
}

.c-form-loader__icon {
  animation: growDot 0.8s linear 0.15s infinite;
  content: none;
  left: 21px;
}

.c-form-loader__icon:after {
  animation: growDot 0.8s linear 0.3s infinite;
  left: 15px;
}

@keyframes growDot {
  0% {
    box-shadow: 0 0 0 0 $color-feature-primary;
  }

  25% {
    box-shadow: 0 0 0 3px $color-feature-primary;
  }

  50% {
    box-shadow: 0 0 0 0 $color-feature-primary;
  }

  100% {
    box-shadow: 0 0 0 0 $color-feature-primary;
  }
}
