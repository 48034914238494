.c-footer-legal {
  color: $color-feature-primary;
  @include rem(font-size, 13px);
  margin-bottom: 0;
  margin-top: 32px;
  text-align: center;

  @include bp-medium {
    @include rem(font-size, 14px);
    margin-bottom: 0;
    margin-top: 54px;
    text-align: left;
  }
}
