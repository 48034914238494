$halfGrid: (
  columns: 2,
  gutters: 20px/550px,
  math: fluid,
);

@include with-layout($halfGrid) {
  .c-form__inner {
    @include container;
    margin-bottom: 0;
  }

  .c-form__col {
    @include rem(margin-bottom, 15px);
    position: relative;
    @include span(2);

    @include bp-small {
      @include span(1);
    }

    @include bp-medium {
      margin-bottom: gutter();
    }

    &.c-form__col--textarea {
      @include span(2);
    }

    &:nth-child(2n) {
      @include bp-small {
        margin-right: 0;
      }
    }

    fieldset {
      margin-bottom: 0;
    }

    .o-list-bare,
    .o-list-bare li {
      margin-bottom: 0;
    }
  }
}

.c-form {
  padding: 15px 0;
  position: relative;

  @include bp-medium {
    @include span(8);
    padding: 0;
    float: none;
    margin: auto;
  }
  
  &:before {
    background-color: #fff;
    content: "";
    bottom: 0;
    left: -15px;
    position: absolute;
    right: -15px;
    top: 0;
    z-index: -1;

    @include bp-small {
      left: -20px;
      right: -20px;
    }

    @include bp-medium {
      border-radius: 8px;
      bottom: -30px;
      left: -30px;
      right: -30px;
      top: -30px;
    }
  }
}

.c-form__col--subscribe {
  margin-bottom: 20px;

  @include bp-small {
    margin-right: 0;
    width: 60%;
  }
}

.c-form__col--submit {
  @include bp-small {
    margin-right: 0;
    width: 100%;
  }

  .c-form-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include bp-small {
      float: right;
    }
  }
}

.c-form__col__checkboxes {
  padding-top: 10px;

  @include bp-small {
    padding-top: 6px;
  }

  @include bp-medium {
    padding-top: 10px;
  }
}

.parsley-errors-list {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  top: 6px;

  li {
    color: #be3333;
    font-size: 9px;
    font-weight: bold;
    -webkit-font-smoothing: auto;
    line-height: 1;
    margin-bottom: 0;
    padding: 4px 7px 3px;
    text-transform: uppercase;
  }
}
