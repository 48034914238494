.c-breadcrumb {
  text-align: center;
  margin-top: 32px;

  @include bp-medium {
    margin-top: 40px;
  }
}

.c-breadcrumb__inner {
  background: $color-brand-grey;
  border-radius: 50px;
  display: inline-block;
  padding: 6px 16px 10px;
  line-height: 1;
}

.c-breadcrumb__link,
.c-breadcrumb__span {
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 4px;
}

.c-breadcrumb__link {
  text-decoration: none;

  @include hover {
    text-decoration: underline;
  }
}
