.c-mobile-nav {
  text-align: center;
}

.c-mobile-nav__list-item {
  margin-bottom: 12px;
  opacity: 0;
  position: relative;
  transition: opacity 150ms ease-in-out;

  .u-nav-open & {
    opacity: 1;
    transition: opacity 300ms 100ms ease-in-out;
  }
}

.c-mobile-nav__link {
  font-size: 18px;
  padding: 4px 2px;
  text-decoration: none;
}

.c-mobile-nav__link--active {
  @include text-underline;
}

.c-site-nav__children {
  .c-mobile-nav__list-item {
    margin-bottom: 6px;
  }
  
  .c-mobile-nav__link {
    font-size: 16px;
  }
}
