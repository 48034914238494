@mixin panel-padding {
  padding-bottom: 40px;
  padding-top: 40px;

  @include bp-small {
    padding-bottom: 48px;
    padding-top: 48px;
  }

  @include bp-medium {
    padding-bottom: 56px;
    padding-top: 56px;
  }

  @include bp-large {
    padding-bottom: 72px;
    padding-top: 72px;
  }

  @include bp-xlarge {
    padding-bottom: 96px;
    padding-top: 96px;
  }

  // @include bp-jumbo {
  //   padding-bottom: 120px;
  //   padding-top: 120px;
  // }
}
