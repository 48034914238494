.c-footer-nav {
  padding: 0 16px;
  margin-bottom: 16px;

  @include bp-medium {
    display: flex;
    padding: 0;
    margin-bottom: 0;
  }
}

.c-footer-nav__list {
  float: left;
  margin-bottom: 0;
  width: 50%;

  @include bp-large {
    @include span(4);
  }
}

.c-footer-nav__list-item {
  display: block;
  margin-bottom: 10px;

  .c-footer-nav__sublist & {
    @include rem(font-size, 14px);
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: 16px;

    @include bp-xlarge {
      @include rem(font-size, 16px);
      margin-bottom: 4px;
    }
  }
}

.c-footer-nav__link {
  color: $color-nav-text;
  text-decoration: none;
  @include large-copy;

  @include bp-medium {
    @include rem(font-size, 16px);
  }

  @include bp-xlarge {
    @include rem(font-size, 18px);
  }

  &:hover,
  &:focus {
    color: $color-brand-grey;
    text-decoration: underline;
  }

  .c-footer-nav__sublist & {
    @include rem(font-size, 14px);
    font-weight: 400;

    @include bp-medium {
      @include rem(font-size, 15px);
    }

    @include bp-xlarge {
      @include rem(font-size, 16px);
    }
  }
}

.c-footer-nav__sublist {
  border-left: 4px solid $color-brand-orange;
}
