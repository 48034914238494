.c-quote {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: center;
  position: relative;

  @include bp-medium {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
}

.c-quote__copy {
  @include large-copy;
  margin-bottom: 24px;

  @include bp-medium {
    margin-bottom: 32px;
  }

  @include bp-jumbo {
    margin-bottom: 56px;
  }
}

.c-quote__attribution {
  background-color: $color-brand-grey;
  border-radius: 8px;
  display: inline-block;
  padding: 8px 16px;
  @include large-copy;
  margin-bottom: 32px;

  @include bp-medium {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  .c-quote-wrapper:first-child:last-child & {
    margin-bottom: 0;
  }
}

.c-quote__author-company {
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
}

.slick-track {
  display: flex;
}

.slick-slide,
.slick-initialized .slick-slide {
  height: auto;
}

.slick-dots {
  line-height: 1;
  list-style: none;
  margin: 0;
  text-align: center;

  @include bp-medium {
    position: absolute;
    bottom: 8px;
    right: 0;
  }

  @include bp-xlarge {
    transform: translateX(50%);
  }

  li {
    display: inline-block;
    line-height: 1;
    margin: 0 5px;

    button {
      background-color: #ffffff;
      border-radius: 50%;
      border: 0;
      color: transparent;
      height: 14px;
      outline: 0;
      padding: 0;
      -webkit-appearance: none;
      width: 14px;

      @include hover {
        background-color: $color-brand-grey;
      }
    }

    &.slick-active {
      button {
        background-color: #ccc;
      }
    }
  }
}
