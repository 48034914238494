%icomoon {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  
  /* Better Font Rendering =========== */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

[class^=icon-], [class*=icon-] {
  
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?fcl1hj');
  src:  url('/assets/fonts/icomoon.eot?fcl1hj#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?fcl1hj') format('truetype'),
    url('/assets/fonts/icomoon.woff?fcl1hj') format('woff'),
    url('/assets/fonts/icomoon.svg?fcl1hj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-chevron-down:before {
  content: "\e911";
}
.icon-link:before {
  content: "\e910";
}
.icon-close:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e900";
}
.icon-google-plus:before {
  content: "\e901";
}
.icon-linked-in:before {
  content: "\e902";
}
.icon-locations:before {
  content: "\e903";
}
.icon-mail:before {
  content: "\e904";
}
.icon-map:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e906";
}
.icon-quotes:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e908";
}
.icon-checkmark-circle:before {
  content: "\e909";
}
.icon-cross-circle:before {
  content: "\e90a";
}
.icon-envelope:before {
  content: "\e90d";
}
.icon-map-marker:before {
  content: "\e90e";
}
.icon-smartphone:before {
  content: "\e90f";
}
.icon-tick:before {
  content: "\e90c";
  color: #ffcb48;
}
