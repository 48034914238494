.c-image {
}

.c-image--narrow {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.c-image--shadow {
  box-shadow: 0 0 40px 0 rgba(#000, 0.1);
}
